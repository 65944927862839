// REACT
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
// ACTION
import {updateConfig, updateSession, updateUser, updateWarning} from 'action';
import {existSession, getChannels, getCustomizationBody, isConvertedJSON} from 'application';
// COMPONENTS
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import ChatButton from './ChatButton';
import StatusContainer from './StatusContainer';
import ZendeskGuide from "./ZendeskGuide/index.js";
import MessageInfo from "./MessageInfo";
// INFRASTRUCTURE
import {CustomRequest} from 'infrastructure/customRequest';
import {Messages} from 'infrastructure/messages';
import {integrationCall} from 'infrastructure/integrationCall';
import {handleWebsocket} from 'infrastructure/centribal_websocket';
import {CustomizeCSS} from 'infrastructure/customize_css';
import {handleChannelState} from 'infrastructure/handleChannels';
import {Artifacts} from 'infrastructure/artifacts';
// JQUERY
import $ from 'jquery';

class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: 'button',
            warning: false,
            position: '',
            fullWidthLogo: false,
            mobile: false,
            call: false,
            navigatorLang: navigator.language || navigator.userLanguage,
            hostName: '',
            values: '',
            screenRemote: false,
            visibilityChat: false,
            initChat: false,
            showChat: false,
            controlIframe: false,
            loading_messages: props.data.channel !== "zendesk_chat",
            sending_attachment: false,
            reconnection: false,
            in_mobile: false,
            zendesk_guide: false,
            websocket_opened: false,
            bad_request: false
        };
    }

    componentDidMount = async () => {
        this.minimizeWidget();
        window.parent.postMessage({init: true, origin: process.env.REACT_APP_URL_CHAT}, '*');

        if(navigator.userAgent.indexOf('iPhone') > -1 ) {
            document
                .querySelector("[name=viewport]")
                .setAttribute("content","width=device-width, initial-scale=1, maximum-scale=1");
        }

        const {channel} = this.props.data;
        if (channel === 'ivr_powers') {
            const req = new handleChannelState(this.props);
            await req.removeChannel();
        }

        const params = new URLSearchParams(window.location.search),
            keys = params.keys(),
            position = params.get('position'),
            fullWidthLogo = params.get('fullWidthLogo'),
            hostName = params.get('hostName'),
            mode = params.get('mode'),
            controlIframe = params.get('control_iframe'),
            user_image_url = params.get('user_image_url'),
            in_mobile = params.get('in_mobile');

        var values = {},
            regex = /^[a-zA-Z_]*$/gm,
            set_value = false;

        for (const key of keys) {
            if (!['position', 'key', 'fullWidthLogo', 'hostName', 'mode', 'in_mobile', 'user_image_url', 'no_animation', 'control_iframe', 'ref_id'].includes(key) && key?.match(regex) && params.get(key)) {
                values[key] = params.get(key);
                set_value = true;
            }
        }

        this.setState({
            hostName: hostName,
            values: set_value ? values : "",
            position: position ?? '',
            fullWidthLogo: !!fullWidthLogo,
            showChat: mode === 'static',
            controlIframe: controlIframe,
            in_mobile: in_mobile
        });

        window.addEventListener("message", function (e) {
            if (e?.data?.type === "send_values" && e?.data?.values) {
                this.setState({
                    values: {...this.state.values, ...e?.data?.values}
                })
            } else if (controlIframe && e?.data?.type) {
                switch (e?.data?.type) {
                    case 'show_chat':
                        this.openChat(e, controlIframe);
                        this.setState({visibilityChat: true});
                        break;
                    case 'hide_chat':
                        this.minimizeWidget();
                        this.setState({visibilityChat: false});
                        break;
                    case 'send_message':
                        if (e?.data?.message) {
                            this.incomingResponses(e.data.message);
                        }
                        break;
                }
            } else {
                if (e?.data?.id) {
                    this.handleCustomization(e.data);
                }
                if (!this.state.showChat) {
                    this.setState({showChat: true});
                }
                if (e && e.data && e.data.mobile !== this.state.mobile) {
                    this.setState({mobile: e.data.mobile});
                }
            }
        }.bind(this));

        this.getConfig(controlIframe, user_image_url);
    }

    getConfig = async (controlIframe, user_image_url) => {
        const {config, projectID, chatID, modeCookie} = this.props.data;
        if (projectID && chatID && !config) {
            try {
                const request = new CustomRequest('GET', `projects/${projectID}/chats/${chatID}/config?platform=chatweb&test=${modeCookie}`, null);
                const res = await request.action();

                this.helperConfig(controlIframe, res, user_image_url);
            } catch (err) {
                if (err.status === 400) {
                    this.setState({ warning: true, visibilityChat: true, bad_request: true });
                } else {
                    this.setState({ warning: true, visibilityChat: true });
                }
            }
        } else if (config && config.id) {
            this.helperConfig(controlIframe, config, user_image_url);
        } else {
            this.setState({warning: true, visibilityChat: true});
        }
    }

    helperConfig = (controlIframe, config, user_image_url) => {
        this.handleChannels(config.channels);
        this.handleCustomization(config);

        config.controlIframe = controlIframe;
        this.handleConfig(config, user_image_url);
    }

    openChat = (e, controlIframe) => {
        if (!this.state.initChat) {
            this.isSession(controlIframe);
            this.setState({initChat: true});
        }

        this.setState({visible: 'all'});
        window.parent.postMessage({visible: 'all'}, '*');
    }

    isSession = async (controlIframe) => {
        const {
            sessionID,
            accountKEY,
            initConversation,
            webRTC_channel,
            projectID,
            chatID,
            userID,
            config,
            modeCookie
        } = this.props.data;

        if (config && config.id) {
            const {navigatorLang, hostName} = this.state;
            if (!sessionID || !userID) {
                var obj = {
                    lang: navigatorLang,
                    user_id: userID,
                    domain: hostName,
                    user: {
                        values: this.state.values
                    }
                }

                try {
                    const request = new Messages('POST', `projects/${projectID}/chats/${chatID}/users?test=${modeCookie}`, JSON.stringify(obj));
                    const res = await request.action();

                    const body = {
                        session: res.session_id,
                        channel: false,
                        account_key: accountKEY,
                        init: initConversation,
                        webRTC_channel: webRTC_channel,
                    }

                    handleWebsocket(controlIframe, this, res.user_id);
                    this.state.websocket_opened = true;

                    this.props.dispatch(updateSession(JSON.stringify(body)));
                    this.props.dispatch(updateUser(JSON.stringify({
                        user_id: res.user_id
                    })));

                    this.handleChannels(config.channels, res.desk);
                    this.setState(({loading_messages: false}));

                    this.welcomeMessage(config, res.session_id, res.user_id);
                } catch (err) {
                    this.props.dispatch(updateConfig(''));
                    this.setState({warning: true});
                }
            } else if (!this.state.initChat && (projectID && chatID)) {
                this.setState({warning: false});
                this.getDesk(controlIframe);
            } else if (!this.state.initChat) {
                this.setState({warning: true});
            }
        } else {
            this.setState({warning: true});
        }
    }

    getHoursDiff = (date) => {
        const msInHour = 1000 * 60 * 60,
            userDate = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Madrid"})),
            messageTime = new Date(date);

        return Math.round(Math.abs(messageTime - userDate) / msInHour);
    }

    historicMessages = async (controlIframe, config, sessionID, userID, projectID, reconnection) => {
        const {channel, chats} = this.props.data;
        var last_message_timestamp;
        if (reconnection && chats.length > 0) {
            last_message_timestamp =  this.props.data.chats[this.props.data.chats.length-1]?.timestamp;
        }

        if (channel !== 'zendesk_chat') {
            try {
                const request = new Messages('GET', `projects/${projectID}/users/${userID}/messages/history?limit=50&created_at=${last_message_timestamp ? parseInt((last_message_timestamp/1000).toString().slice(0, -2)) : null}`, null);
                const res = await request.action();
                if (res.length === 0 && !last_message_timestamp) {
                    this.welcomeMessage(config, sessionID, userID);
                    this.setState({loading_messages: false, reconnection: false});
                } else {
                    setTimeout(() => {
                        this.setState({loading_messages: false, reconnection: false});
                        var count = 0;
                        res.forEach(message => {
                            var created_at = message.created_at,
                                historic_messages = parseInt(config?.customization?.historic_messages),
                                difference_hours = this.getHoursDiff(created_at),
                                difference_timestamps = + new Date(created_at) - last_message_timestamp,
                                is_bot = message.is_bot;

                            if (difference_hours <= historic_messages && historic_messages !== 0 && !last_message_timestamp || last_message_timestamp && difference_timestamps > 0) {
                                count++;
                                var msg = message?.text?.replace(/(?:\r\n|\r|\n)/g, '<br>');

                                if (!is_bot) {
                                    this.props.dispatch({
                                        type: 'synthetic',
                                        detail: {
                                            type: 'visitor_send_msg',
                                            msg,
                                            created_at: + new Date(message.created_at) + count,
                                            desk_name: message.desk_name
                                        }
                                    });
                                }

                                var artifacts = new Artifacts(message.text),
                                    elements = artifacts.process();

                                if (message.attachments.length > 0) {
                                    if (is_bot) {
                                        this.handleElements(elements, msg, true);
                                    }
                                    message.attachments.forEach(attachment => {
                                        var type = attachment.type;
                                        if (attachment.type === "file") {
                                            type = attachment.filetype;
                                        }
                                        switch (type) {
                                            case 'image':
                                            case 'document':
                                            case 'video':
                                            case 'audio':
                                            case 'application':
                                                if (!is_bot) {
                                                    const message = {
                                                        attachment,
                                                        display_name: "Visitor",
                                                        nick: "visitor",
                                                        timestamp: +new Date(),
                                                        type: "chat.file"
                                                    }

                                                    this.props.dispatch({
                                                        type: 'file_from_chat',
                                                        detail: message
                                                    });
                                                } else {
                                                    this.props.dispatch({
                                                        type: 'file',
                                                        detail: {msg, attachment, created_at: message.created_at}
                                                    });
                                                }
                                                break;
                                        }
                                    });
                                } else if (is_bot) {
                                    this.handleElements(elements, msg, true);
                                }
                            }
                        })

                        if (count === 0 && !last_message_timestamp) {
                            this.welcomeMessage(config, sessionID, userID);
                        }
                    }, 500);
                }
            } catch (err) {
                this.setState({loading_messages: false, reconnection: false});
            }
        }
    }

    welcomeMessage = (config, sessionID, userID) => {
        const {projectID, channel} = this.props.data;

        var welcome_selector = config?.customization?.welcome_selector,
            welcome_message = config?.text_welcome,
            welcome_incoming = config?.customization?.welcome_incoming;

        if (!channel) {
            if (welcome_selector === "incoming" && welcome_incoming) {
                this.incomingResponses(welcome_incoming, sessionID, userID);
            } else if (projectID === 'e912cb15787a4bdc8e18fc0559ea91dc') {
                this.incomingResponses(this.props.t('tradeInn_welcome'), sessionID, userID);
            } else if (projectID === 'e49682152d784785a16f5a06f43b61ac') {
                this.incomingResponses(this.props.t('drivania_welcome'), sessionID, userID);
            } else {
                var artifacts = new Artifacts(welcome_message),
                    elements = artifacts.process();
                this.handleElements(elements, welcome_message.replace(/(?:\r\n|\r|\n)/g, '<br>'));
                this.incomingResponses(welcome_message, sessionID, userID, true);
            }
        }

        this.setState({loading_messages: false, reconnection: false});
    }

    getDesk = async (controlIframe) => {
        const {projectID, chatID, sessionID, modeCookie, config, userID} = this.props.data;
        if (projectID && chatID) {
            try {
                const request = new Messages('GET', `projects/${projectID}/chats/${chatID}/users/${userID}?test=${modeCookie}`, null);
                const res = await request.action();

                this.props.dispatch(updateUser(JSON.stringify({
                    user_id: res.user_id
                })))

                this.historicMessages(controlIframe, config, sessionID, res.user_id, projectID, true);
                if (this.state.websocket_opened === false) {
                    handleWebsocket(controlIframe, this, res.user_id);
                    this.state.websocket_opened = true;
                }

                this.handleChannels(config.channels, res.desk);

                this.setState({warning: false});
            } catch (err) {
                this.setState({warning: true});
            }
        } else {
            this.setState({warning: true});
        }
    }

    handleCustomizationActions = (google_analytics) => {
        if (google_analytics) {
            var script_inline = document.createElement("script");
            script_inline.src = `https://www.googletagmanager.com/gtag/js?id=${google_analytics}`;
            script_inline.async = true;

            document.body.appendChild(script_inline);

            var script_content = document.createElement("script"),
                content = document.createTextNode(`window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', "${google_analytics}");`);

            script_content.appendChild(content);

            document.body.appendChild(script_content);
        }
    }

    initChat = async (channel) => {
        const req = new integrationCall(this.props, channel, 'init', null);
        await req.call();
    }

    handleOnSubmit = (e) => {
        e && e.preventDefault();

        const {channel} = this.props.data;
        const msg = $('#b-chat-input').val();

        if (!msg) return;

        if (!this.controlMessages(msg)) return;

        $('#b-chat-input').val('');

        var whitespaces = !msg?.replace(/\s/g, '').length;
        if (!whitespaces) {
            this.props.dispatch({type: 'synthetic', detail: {type: 'visitor_send_msg', msg, desk_name: channel}});
            if (channel !== 'zendesk_chat') {
                setTimeout(() => {
                    this.incomingResponses(msg);
                }, 100);
            } else {
                existSession();
                this.integrationSendMessage(msg, 'sendMessage');
            }
        }
    }

    controlMessages = (msg) => {
        const {channel, chats} = this.props.data;
        var messages = chats.toArray(),
            last_iteration = messages.length - 1,
            last_msg = messages[last_iteration];

        if (messages.length > 0 && last_msg.msg === msg && channel === "zendesk_full" && last_msg.desk_name === "zendesk_full" && last_msg.display_name === "visitor") {
            this.props.dispatch(updateWarning({type: "duplicate_message"}));
            return false;
        }

        return true;
    }

    handleFileUpload = (e) => {
        e && e.preventDefault();
        const {channel} = this.props.data;
        const file = e.target.files[0];

        if (file) {
            if (file?.size <= 50000000) {
                if (channel === "zendesk_chat") {
                    const attachment = {
                        mime_type: file.type,
                        name: file.name,
                        size: file.size,
                        url: window.URL.createObjectURL(file)
                    }

                    const message = {
                        attachment,
                        display_name: "Visitor",
                        nick: "visitor",
                        timestamp: +new Date(),
                        type: "chat.file"
                    }

                    this.props.dispatch({
                        type: 'file_from_chat',
                        detail: message
                    });

                    this.integrationSendMessage(file, 'sendFile');
                } else {
                    this.setState({sending_attachment: true});

                    var formData = new FormData()
                    formData.append("file", file);
                    formData.append("mediasize", file?.size);

                    this.incomingAttachments(formData);
                }
            } else {
                this.props.dispatch({
                    type: 'synthetic',
                    detail: {type: 'agent_send_msg', msg: this.props.t('attachment_limit_50_MB_error')}
                });
            }
        }
    }

    handleUpdateUser = () => {
        const {userID} = this.props.data;
        this.props.dispatch(updateUser(JSON.stringify({
            user_id: userID
        })))
    }

    initConversation = async () => {
        const request = new handleChannelState(this.props);
        await request.init();
    }

    integrationSendMessage = async (msg, type) => {
        const request = new integrationCall(this.props, this.props.data.channel, type, msg);
        await request.call();
    }

    incomingResponses = async (message, session_id, user_id, is_welcome) => {
        const {projectID, chatID, sessionID, config, channel, modeCookie, userID} = this.props.data;
        const {hostName, values} = this.state;

        var body = {
            event: is_welcome ? "is_welcome" : "message",
            chat_id: chatID,
            session_id: session_id ? session_id : sessionID,
            content: {
                text: message,
                type: "text"
            },
            user: {
                domain: hostName,
                values: values
            }
        }, whitespaces = !message?.replace(/\s/g, '').length;

        this.handleUpdateUser();

        if (!whitespaces) {
            if (channel === "gpt-3") {
                this.props.dispatch({type: 'typing'});
            }

            try {
                const request = new Messages('POST', `projects/${projectID}/users/${user_id ? user_id : userID}/messages/incoming?test=${modeCookie}`, JSON.stringify(body));
                const res = await request.action();
                if (res.response) {
                    var msg = res.response.replace(/(?:\r\n|\r|\n)/g, '<br>');

                    if (["initcallfromplataformacentribal01", "initcallfromplataformacentribal02"].includes(msg)) {
                        const ivr_powers = new handleChannelState(this.props, msg);
                        switch (msg) {
                            case 'initcallfromplataformacentribal01':
                                ivr_powers.setChannel('ivr_powers', '01');
                                break;
                            case 'initcallfromplataformacentribal02':
                                ivr_powers.setChannel('ivr_powers', '02');
                                break;
                        }
                        msg = "Pulse el botón de videollamada para ser atendido por un agente.";
                    }
                    var artifacts = new Artifacts(res.response),
                        get_elements = artifacts.process();

                    this.handleElements(get_elements, msg);

                    this.handleChannels(config.channels, res.desk);
                    if (channel && !res.desk && this.props.data.channel !== 'ivr_powers') {
                        const req = new handleChannelState(this.props);
                        await req.removeChannel();
                    }
                }

            } catch (err) {
                this.props.dispatch({
                    type: 'synthetic',
                    detail: {type: 'agent_send_msg', msg: this.props.t('incoming_error')}
                });
            }
        }
    }

    incomingAttachments = async (attachment) => {
        const {projectID, userID} = this.props.data;
        try {
            const request = new Messages('POST', `projects/${projectID}/users/${userID}/messages/incoming/attachments`, attachment);
            const res = await request.action(true);

            res.attachments.forEach((attachment => {
                const message = {
                    attachment,
                    display_name: "Visitor",
                    nick: "visitor",
                    timestamp: +new Date(),
                    type: "chat.file"
                }

                this.props.dispatch({
                    type: 'file_from_chat',
                    detail: message
                });

            }));

            this.setState({sending_attachment: false});

            setTimeout(() => {
                if (res.text) {
                    var msg = res.text?.replace(/(?:\r\n|\r|\n)/g, '<br>');

                    var artifacts = new Artifacts(res.text),
                        elements = artifacts.process();

                    this.handleElements(elements, msg);
                }
            }, 1500);
        } catch (err) {
            if (err.status === 422) {
                this.props.dispatch({
                    type: 'synthetic',
                    detail: {type: 'agent_send_msg',msg: this.props.t('extension_not_supported') }
                });
            } else {
                this.props.dispatch({
                    type: 'synthetic',
                    detail: {type: 'agent_send_msg', msg: this.props.t('attachment_error') }
                });
            }
            this.setState({sending_attachment: false});
        }
    }

    handleElements = (elements, msg, isHistory) => {
        if (elements) {
            if (elements.length > 1) {
                elements.forEach((element, i) => {
                    if (isHistory) {
                        this.props.dispatch({type: 'components', detail: {type: 'agent_send_msg', msg: element}});
                    } else {
                        setTimeout(() => {
                            this.props.dispatch({type: 'components', detail: {type: 'agent_send_msg', msg: element}});
                        }, i * 1000)
                    }
                });
            } else {
                this.props.dispatch({type: 'components', detail: {type: 'agent_send_msg', msg: elements[0]}});
            }
        } else if (msg) {
            this.props.dispatch({type: 'synthetic', detail: {type: 'agent_send_msg', msg}});
        }
    }

    getVisibilityClass = () => {
        const {no_animation} = this.props.data;
        if (this.state.visible !== 'button' && this.state.visible !== 'button_expand') {
            if (no_animation) {
                return 'visible_no_animation'
            }
            return 'visible';
        } else if (no_animation) {
            return 'no_animation'
        }
        return ''
    }

    getVisibilityWarn = () => {
        if (this.state.visible === 'all') {
            return 'bim-flx';
        }
        return '';
    }

    minimizeWidget = () => {
        const {modeStatic} = this.props.data;
        const {controlIframe} = this.state;
        this.setState({visible: 'button'});

        if (controlIframe && modeStatic) {
            this.setState({visibilityChat: false});
            window.parent.postMessage({status: 'hide'}, '*');
        }

        window.parent.postMessage({visible: 'button'}, '*');
    }

    maximizeChat = () => {
        this.setState({visible: 'maximize'});
        window.parent.postMessage({visible: 'maximize'}, '*');
    }

    deleteIframe = () => {
        window.parent.postMessage({visible: 'delete'}, '*');
    }

    onHoverUp = () => {
        const {config} = this.props.data;
        if (this.state.visible !== 'all' && config && config.expanded) {
            this.setState({visible: 'button_expand'});
            window.parent.postMessage({visible: 'button_expand'}, '*');
        }
    }

    onHoverDown = () => {
        if (this.state.visible !== 'all') {
            this.setState({visible: 'button'});
            window.parent.postMessage({visible: 'button'}, '*');
        }
    }

    updateCall = (callStatus) => {
        var value = callStatus ? callStatus : !this.state.call;
        this.setState({call: value});
        this.updateHeight(value);
    }

    updateHeight = (callStatus) => {
        var x = 0;
        var intervalID = setInterval(function () {
            if (++x !== 10) {
                var screenremote = document.getElementById('screenremote');
                var removeVideo = document.getElementById('videoremote1');
                var callRTC = document.getElementsByClassName('call-videos')[0];
                var container = document.getElementsByClassName('message-list-content')[0];

                if (!container) {

                }
                var value = '';
                if (callStatus && screenremote) {
                    var opr = removeVideo.offsetHeight - (removeVideo.offsetHeight * 0.20);
                    value = `calc(100% - ${150 + opr}px)`;
                } else if (callStatus && callRTC) {
                    value = `calc(100% - ${150 + callRTC.offsetHeight}px)`;
                } else if (container && !callRTC) {
                    value = `100%`;
                }
                if (container) {
                    container.style.height = value;
                }
            } else {
                window.clearInterval(intervalID);
            }
        }, 250);
    }

    updateScreenRemote = (status) => {
        this.setState({screenRemote: status});
    }

    handleExpandButton = () => {
        setTimeout(() => {
            if (this.state.visible === 'button') {
                this.minimizeChatExpand();
                setTimeout(() => {
                    if (this.state.visible === 'button_expand') {
                        this.minimizeWidget();
                    }
                }, 5000);
            }
        }, 5000);
    }

    minimizeChatExpand = () => {
        const {config} = this.props.data;
        if (config && config.expanded) {
            this.setState({visible: 'button_expand'});
            window.parent.postMessage({visible: 'button_expand'}, '*');
        }
    }

    handleChannels = (channels, desk) => {
        const {channel} = this.props.data;
        const request = new handleChannelState(this.props);

        var channel_select = false;

        getChannels().forEach(ch => {
            if (channels && channels[ch]) {
                channel_select = ch;
            }
        });

        if (channel_select === 'zendesk_chat') {
            request.setZendeskChat(channels.zendesk_chat.account_key);
        }

        if (desk || channel) {
            request.setChannel(![undefined, null].includes(desk) ? desk : channel);
            this.initChat(![undefined, null].includes(desk) ? desk : channel);
            this.initConversation();
        }
    }

    handleCustomization = (config) => {
        const {no_animation} = this.props.data;
        var default_config = getCustomizationBody();

        if (!config.customization) {
            config.customization = default_config;
        } else {
            var incoming_customization = isConvertedJSON(config.customization) ? JSON.parse(config.customization) : config.customization;

            config.customization = {...default_config, ...incoming_customization};
        }

        this.handleCustomizationActions(config.customization.google_analytics);
        if (no_animation) {
            config.open_on_load = true;
        }

        const customization = new CustomizeCSS(this.props, this, config);
        customization.initCustomize();

        this.props.dispatch(updateConfig(config));
    }

    handleConfig = (config, user_image_url) => {
        const {mobile, in_mobile} = this.state;
        var open_on_load = config?.open_on_load,
            open_on_load_timer = parseInt(config?.customization?.open_on_load_timer ?? 0) * 1000,
            chat_timer = parseInt(config?.customization?.chat_timer ?? 0) * 1000;

        if (user_image_url) {
            config.user_icon = user_image_url;
        }

        if (!config.active) {
            this.deleteIframe();
        } else if (!config.controlIframe) {
            if (open_on_load && (!mobile || mobile && in_mobile)) {
                if (open_on_load_timer === 0) {
                    this.openChat();
                    this.setState({visibilityChat: true});
                } else {
                    setTimeout(() => {
                        this.handleExpandButton();

                        this.setState({visibilityChat: true});
                        setTimeout(() => {
                            this.openChat();
                        }, open_on_load_timer);
                    }, chat_timer);
                }
            } else {
                setTimeout(() => {
                    this.handleExpandButton();
                    this.setState({visibilityChat: true});
                }, chat_timer);
            }
        }
    }

    handleZendeskGuide = () => {
        this.setState({zendesk_guide: !this.state.zendesk_guide});
    }

    render() {
        const {config, channel, webRTC_channel, sessionID, projectID, modeCookie, modeStatic, close} = this.props.data;
        const {
            visibilityChat,
            showChat,
            mobile,
            call,
            screenRemote,
            visible,
            warning,
            position,
            fullWidthLogo,
            loading_messages,
            reconnection,
            sending_attachment,
            zendesk_guide,
            controlIframe
        } = this.state;
        const {isOnline, bad_request, t} = this.props;
        this.updateHeight(call);

        return (
            visibilityChat && showChat &&
            <div id={mobile ? 'mobile' : ''}>
                <div className={`widget-container ${this.getVisibilityClass()}`}>
                    {
                        (config && config.id || warning) &&
                        <StatusContainer webRTC={webRTC_channel} updateScreenRemote={this.updateScreenRemote}
                                         screenRemote={screenRemote} call={call} modeCookie={modeCookie}
                                         modeStatic={modeStatic} close={close}
                                         updateCall={this.updateCall} position={visible}
                                         mobile={mobile} channel={channel}
                                         config={config} minimizeChat={this.openChat} maximizeChat={this.maximizeChat}
                                         minimizeWidget={this.minimizeWidget} controlIframe={controlIframe}
                                         handleZendeskGuide={this.handleZendeskGuide} zendeskGuide={zendesk_guide}/>
                    }
                    {
                        config && config.id ? (
                            <React.Fragment>
                                {
                                    zendesk_guide ?
                                        <ZendeskGuide closeZendeskGuide={this.handleZendeskGuide}
                                                      inputSvg={config.customization.input_icon}/> :
                                        <React.Fragment>
                                            <MessageList channel={channel} call={call} visible={visible}
                                                         modeCookie={modeCookie}
                                                         incomingResponses={this.incomingResponses} config={config}
                                                         messages={this.props.data?.chats.toArray()}/>
                                            <MessageInfo loadingMessages={loading_messages} />
                                            {
                                                config.customization.input_show &&
                                                <MessageInput projectID={projectID} sessionID={sessionID}
                                                              channel={channel} reconnection={reconnection}
                                                              isOnline={isOnline}
                                                              sendingAttachment={sending_attachment}
                                                              attachmentActive={config.customization.attachment_clip}
                                                              svg={config.customization.input_icon}
                                                              sendText={config.text_send}
                                                              onSubmit={this.handleOnSubmit}
                                                              onFileUpload={this.handleFileUpload}/>
                                            }
                                        </React.Fragment>
                                }
                            </React.Fragment>
                        ) : (
                            <div className={`bim-warn ${this.getVisibilityWarn()}`}>
                                <img src={'assets/warning.png'} alt={"Warning Message"}/>
                                Lo sentimos, estamos con tareas de mantenimiento. Volvemos en un minuto.
                            </div>
                        )
                    }
                </div>
                {
                    (config && config.id || warning) ? (
                    <ChatButton
                        fullWidthLogo={fullWidthLogo}
                        position={position}
                        config={config}
                        onHoverDown={this.onHoverDown}
                        onHoverUp={this.onHoverUp}
                        distribution={visible}
                        addClass={this.getVisibilityClass()}
                        onClick={this.openChat}
                    />
                    ) : bad_request ? (
                        <div 
                        onClick={() => window.location.reload()}
                        className='reload-container'>
                        <img 
                          src={"assets/reload.png"} 
                          alt="Warning Message" 
                         className='reload-icon' />
                        <span className='reload-text'>
                            {t('reload_page')}
                        </span>
                      </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        data: state
    }
}

const connect_chat = connect(
    mapStateToProps
)(Chat);

export default withTranslation('common')(connect_chat);
